<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhPlus } from '@phosphor-icons/vue';
import { computed, ref, watch } from 'vue';

import { useSortedSections } from '@/composables/useSortedSections';
import type { Section } from '@/types/section';
import type { SectionTypeName } from '@/types/sections/section-type-name';

import SectionFormPreviewItem from './section-form-preview-item.vue';

export type Props = {
  position: number,
  navbar: Section;
  viewSections: Section[];
  footer: Section;
  selectedSectionId: number;
  selectedSectionType: SectionTypeName;
  isDestroyable?: boolean;
  canAddSection?: boolean;
  stop?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isDestroyable: false,
  stop: false,
});
const loadedCount = ref(0);

function countLoad() {
  loadedCount.value += 1;
}

const LAYOUT_SECTIONS_COUNT = 2;

const notAllPreviewsLoaded = computed(() => (
  loadedCount.value < props.viewSections.length + LAYOUT_SECTIONS_COUNT),
);

const emits = defineEmits<{(e: 'update:position', value: number): void,
  (e: 'destroy'): void, (e: 'add'): void, (e: 'stop'): void}>();

const {
  sortedSections: sortedViewSections,
  moveSectionUp,
  moveSectionDown,
  canMoveUp,
  canMoveDown,
  selectedSectionPosition,
  resetPositions,
} = useSortedSections({
  sections: props.viewSections,
  selectedSectionId: props.selectedSectionId,
});

function handleMoveUp() {
  moveSectionUp();
  emits('update:position', selectedSectionPosition.value);
}

function handleMoveDown() {
  moveSectionDown();
  emits('update:position', selectedSectionPosition.value);
}

function isSectionSelected(section: Section) {
  return section.id === props.selectedSectionId;
}

function isSectionDestroyable(section: Section) {
  return props.isDestroyable && isSectionSelected(section);
}

watch(() => props.position, () => {
  if (props.position !== selectedSectionPosition.value) {
    resetPositions();
  }
});

</script>
<template>
  <div>
    <base-svg
      v-show="notAllPreviewsLoaded"
      data-testid="squeleton-icon"
      name="section-preview-squeleton"
      class="fill-gray-100"
    />
    <div
      data-testid="previews-container"
      class="duration-700 ease-in"
      :class="{'opacity-0': notAllPreviewsLoaded }"
    >
      <section-form-preview-item
        data-testid="navbar-preview"
        :section="navbar"
        :selected="isSectionSelected(navbar)"
        :selected-section-type="selectedSectionType"
        center-edit
        :stop="stop"
        @stop="$emit('stop')"
        @loaded="countLoad"
      />
      <section-form-preview-item
        v-for="section in sortedViewSections"
        :key="section.id"
        :section="section"
        :selected="isSectionSelected(section)"
        :selected-section-type="selectedSectionType"
        :can-move-up="canMoveUp"
        :can-move-down="canMoveDown"
        :is-destroyable="isSectionDestroyable(section)"
        :stop="stop"
        @stop="$emit('stop')"
        @destroy="$emit('destroy')"
        @up="handleMoveUp"
        @down="handleMoveDown"
        @loaded="countLoad"
      />
      <div
        v-if="props.canAddSection"
        class="my-4 flex justify-center"
      >
        <mok-button
          data-testid="add-section-button"
          :icon="PhPlus"
          variant="secondary"
          color="purple"
          tone="light"
          :label="$t('sections.addSection')"
          @click="$emit('add')"
        />
      </div>
      <section-form-preview-item
        data-testid="footer-preview"
        :section="footer"
        :selected="isSectionSelected(footer)"
        :selected-section-type="selectedSectionType"
        center-edit
        :stop="stop"
        @stop="$emit('stop')"
        @loaded="countLoad"
      />
    </div>
  </div>
</template>
