<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhTrash, PhWarning } from '@phosphor-icons/vue';
import { ref, computed, provide } from 'vue';

import BaseBadge from '@/components/base-badge.vue';
import BaseMokAlertModal from '@/components/base-mok-alert-modal.vue';
import theWebAppLayout from '@/components/the-web-app-layout.vue';
import { useSectionCreate } from '@/composables/useSectionCreate';
import { useSectionDestroy } from '@/composables/useSectionDestroy';
import { useSectionUpdate } from '@/composables/useSectionUpdate';
import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { DesignConfig } from '@/types/design-config';
import type { Section } from '@/types/section';
import type { SectionBaseTypeName } from '@/types/section-base-type-name';
import type { SectionOptions } from '@/types/sections/section-options';
import type { WebApp } from '@/types/web-app';
import type { WebAppTabs } from '@/types/web-app-tabs';
import getSectionFieldComponent from '@/utils/get-section-field-component';

import SectionFormCreateModal from './section-form-create-modal.vue';
import SectionFormDescription from './section-form-description.vue';
import SectionFormPreview from './section-form-preview.vue';
import SectionFormStyleSelect from './section-form-style-select.vue';
import ThePageHeader from '../the-page-header.vue';

export type Props = {
  webApp: WebApp,
  designConfig?: DesignConfig;
  section: Section;
  options: SectionOptions;
  navbar: Section;
  viewSections: Section[];
  footer: Section;
  backPath: string;
  tabs: WebAppTabs;
  additionableBaseTypes: SectionBaseTypeName[];
}

const props = defineProps<Props>();
provide('designConfig', props.designConfig);

const {
  currentSection,
  currentSectionFields,
  updateSection,
  isUpdateSuccess,
  isUpdateError,
  isUpdateLoading,
  needsSave,
  resetForm,
} = useSectionUpdate(
  { section: props.section, options: props.options },
);

const selectedOptionFields = computed(() => (props.options[currentSection.type]));

const VISIBILITY_TIMEOUT = 3000;

const { isVisible: isUpdateSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateSuccess, timeout: VISIBILITY_TIMEOUT },
);
const { isVisible: isUpdateErrorMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateError, timeout: VISIBILITY_TIMEOUT },
);

const {
  showDestroyModal,
  openDestroyModal,
  closeDestroyModal,
  destroySection,
  isLoading: isDestroyLoading,
  isError: isDestroyError,
} = useSectionDestroy(
  { sectionId: props.section.id, successRedirectPath: props.navbar.path },
);

const {
  showCreateModal,
  openCreateModal,
  closeCreateModal,
  createSection,
  isCreateLoading,
  isCreateError,
} = useSectionCreate(
  { webAppId: props.webApp.id },
);

const { isVisible: isDestroyErrorMessageVisible } = useVisibilityTimeout(
  { condition: isDestroyError, timeout: VISIBILITY_TIMEOUT },
);

const isErrorMessageVisible = computed(() => (
  isUpdateErrorMessageVisible.value || isDestroyErrorMessageVisible.value || isCreateError.value
));

const canAddSection = computed(() => (
  props.additionableBaseTypes.length > 0
));

const showUnsavedChangesModal = ref(false);

function openUnsavedChangesModal() {
  showUnsavedChangesModal.value = true;
}

function closeUnsavedChangesModal() {
  showUnsavedChangesModal.value = false;
}

function resetUnsavedChanges() {
  resetForm();
  closeUnsavedChangesModal();
}

function confirmSaveChanges() {
  updateSection();
  showUnsavedChangesModal.value = false;
}

function onDelete(sectionFieldName: string) {
  currentSectionFields[sectionFieldName] = '';
}

</script>

<template>
  <the-web-app-layout
    data-testid="the-web-app-layout"
    :web-app="webApp"
    selected-tab="sections"
    :tabs="tabs"
    :back-path="backPath"
  >
    <the-page-header
      :title="$t('sections.pageTitle')"
      :description="$t('sections.pageDescription')"
      emoji="⭐️"
    >
      <template #notice>
        <base-badge
          v-if="isErrorMessageVisible"
          :label="$t('common.saveError')"
          color="error"
          theme="dark"
        />
        <base-badge
          v-else-if="isUpdateSuccessMessageVisible"
          :label="$t('common.savedSuccessfully')"
          color="success"
          theme="dark"
        />
      </template>
    </the-page-header>
    <base-mok-alert-modal
      data-testid="destroy-section-modal"
      :is-open="showDestroyModal"
      :loading="isDestroyLoading"
      :icon="PhTrash"
      icon-color="warning"
      :title="$t('sections.destroyModal.title')"
      :cancel-label="$t('actions.cancel')"
      :confirm-label="$t('actions.delete')"
      @cancel="closeDestroyModal"
      @close="closeDestroyModal"
      @confirm="destroySection"
    >
      <p>
        {{ $t('sections.destroyModal.description') }}
      </p>
    </base-mok-alert-modal>
    <base-mok-alert-modal
      data-testid="unsaved-changes-modal"
      :is-open="showUnsavedChangesModal"
      :icon="PhWarning"
      icon-color="warning"
      :title="$t('sections.unsavedChangesModal.title')"
      :cancel-label="$t('actions.discard')"
      :confirm-label="$t('actions.saveChanges')"
      @cancel="resetUnsavedChanges"
      @close="closeUnsavedChangesModal"
      @confirm="confirmSaveChanges"
    >
      <p>
        {{ $t('sections.unsavedChangesModal.description') }}
      </p>
    </base-mok-alert-modal>
    <section-form-create-modal
      :is-open="showCreateModal"
      :loading="isCreateLoading"
      :additionable-base-types="additionableBaseTypes"
      @close="closeCreateModal"
      @create="(baseType: SectionBaseTypeName) => createSection(baseType)"
    />
    <div class="flex flex-col pb-8 md:grid md:grid-cols-2 md:gap-8">
      <section-form-preview
        v-model:position="currentSection.position"
        :navbar="navbar"
        :view-sections="viewSections"
        :footer="footer"
        :selected-section-id="section.id"
        :selected-section-type="currentSection.type"
        :is-destroyable="section.destroyable"
        :can-add-section="canAddSection"
        :stop="needsSave"
        @stop="openUnsavedChangesModal"
        @destroy="openDestroyModal"
        @add="openCreateModal"
      />
      <div class="relative flex-col space-y-6 md:col-span-1">
        <section-form-description
          :section-base-type="currentSection.baseType"
        />
        <form
          v-if="section.editable"
          class="flex flex-col space-y-3"
        >
          <section-form-style-select
            v-model="currentSection.type"
            data-testid="section-options"
            :section-base-type="currentSection.baseType"
            :selected-type="currentSection.type"
            :options="options"
          />
          <component
            :is="getSectionFieldComponent(sectionFieldSchema)"
            v-for="(sectionFieldSchema, sectionFieldName) in selectedOptionFields"
            :key="`${currentSection.type}-${sectionFieldName}`"
            v-model="currentSectionFields[sectionFieldName]"
            :base-type="currentSection.baseType"
            :name="sectionFieldName"
            @delete="onDelete(sectionFieldName.toString())"
          />
          <div class="sticky bottom-0 right-0 flex w-full flex-col-reverse justify-end gap-4 bg-white py-4 lg:flex-row">
            <mok-button
              type="button"
              data-testid="reset-button"
              variant="secondary"
              :disabled="!needsSave"
              :label="$t('actions.discard')"
              @click="resetForm"
            />
            <mok-button
              type="button"
              data-testid="update-button"
              variant="primary"
              :disabled="!needsSave"
              :loading="isUpdateLoading"
              :label="$t('actions.saveChanges')"
              @click="updateSection"
            />
          </div>
        </form>
      </div>
    </div>
  </the-web-app-layout>
</template>
