<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhArrowLeft } from '@phosphor-icons/vue';
import { computed, inject } from 'vue';

import type { Country } from '@/types/country';
import type { SalesHerokuApp } from '@/types/sales-heroku-app';
import type { Sponsor } from '@/types/sponsor';
import type { User } from '@/types/user';

import WebAppsNewMaster from './web-apps-new-master.vue';
import WebAppsNewSales from './web-apps-new-sales.vue';

defineProps<{
  sponsors: Sponsor[];
  backPath: string;
  countries: Country[];
  availableSalesHerokuApps?: SalesHerokuApp[];
  salesSponsorId: number;
  countriesWithAvailableSalesHerokuApps: Country[];
}>();

const currentUser = inject<User>('currentUser');

const canCreateProductiveApp = computed(() => currentUser?.role === 'master');

</script>
<template>
  <the-navbar />
  <div
    class="grid grid-cols-1 space-y-5 p-8 md:px-48"
  >
    <div class="flex flex-col items-start gap-10">
      <mok-button
        variant="link"
        :icon="PhArrowLeft"
        :href="backPath"
        :label="$t('actions.return')"
      />
      <h1 class="text-xl font-semibold">
        {{ $t('webApp.titles.new') }}
      </h1>
    </div>
    <web-apps-new-master
      v-if="canCreateProductiveApp"
      :back-path="backPath"
      :sponsors="sponsors"
      :countries="countries"
      :sales-sponsor-id="salesSponsorId"
      :countries-with-available-sales-heroku-apps="countriesWithAvailableSalesHerokuApps"
    />
    <web-apps-new-sales
      v-else
      :back-path="backPath"
      :countries-with-available-sales-heroku-apps="countriesWithAvailableSalesHerokuApps"
    />
  </div>
</template>
