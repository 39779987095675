<script setup lang="ts">
import { computed, inject } from 'vue';

import { usePermissions } from '@/composables/usePermissions';
import type { Permissions } from '@/types/permissions';

export type Props = {
  mode: 'show' | 'add'
  willBeAdded?: boolean;
}

const props = withDefaults(defineProps<Props>(),
  {
    willBeAdded: undefined,
  },
);

const { resourcePermissions: webAppCouponsPermissions } = usePermissions(
  'webAppCoupons', inject<Permissions>('permissions'),
);

interface Emits {
  (e: 'edit'): void;
  (e: 'delete'): void;
  (e: 'add'): void;
  (e: 'undoAdd'): void;
}

const showEditAndDeleteButtons = computed(() => props.mode === 'show');
const showAddButton = computed(() => props.mode === 'add' && !props.willBeAdded);
const showUndoAddButton = computed(() => props.mode === 'add' && props.willBeAdded);

defineEmits<Emits>();
</script>
<template>
  <template
    v-if="showEditAndDeleteButtons"
  >
    <base-button
      v-if="webAppCouponsPermissions.update"
      data-testid="edit-coupon-button"
      icon-file-name="pencil"
      size="md"
      @click="$emit('edit')"
    />
    <base-button
      v-if="webAppCouponsPermissions.destroy"
      data-testid="delete-coupon-button"
      icon-file-name="trash"
      size="md"
      @click="$emit('delete')"
    />
  </template>
  <base-button
    v-if="showAddButton"
    data-testid="add-coupon-button"
    icon-file-name="add"
    :label="$t('functionalities.coupons.addCoupon')"
    size="sm"
    theme="light-link"
    @click="$emit('add')"
  />
  <base-button
    v-if="showUndoAddButton"
    data-testid="undo-add-coupon-button"
    icon-file-name="arrow-uturn-left"
    :label="$t('actions.undo')"
    size="sm"
    theme="primary-link"
    @click="$emit('undoAdd')"
  />
</template>
