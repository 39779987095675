<script setup lang="ts">
interface RadioOption {
  value: unknown;
  label: string;
}

interface Props {
  modelValue: unknown;
  options: RadioOption[];
  name: string;
  label?: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: unknown): void}>();

function selectOption(option: string) {
  emit('update:modelValue', option);
}
</script>

<template>
  <div class="flex">
    <div
      v-if="label"
      class="mr-8 text-sm font-semibold leading-8 text-gray-900"
    >
      {{ label }}
    </div>
    <div class="flex space-x-6 text-xs">
      <base-radio-input
        v-for="option in props.options"
        :key="option.label"
        :model-value="modelValue"
        :value="option.value"
        :name="name"
        :label="option.label"
        @update:model-value="selectOption"
      />
    </div>
  </div>
</template>
