import { decamelize } from 'humps';
import { serialize } from 'object-to-formdata';

import { SalesWebAppForm } from '@/types/sales-web-app-form';
import type { WebApp } from '@/types/web-app';
import { WebAppForm } from '@/types/web-app-form';
import type { WebAppOrderParam } from '@/types/web-app-order-param';

import api from './index';

const BASE_PATH = '/api/internal/web_apps';

export default {
  create(webApp: WebAppForm | SalesWebAppForm) {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: serialize(
        { webApp },
      ),
    });
  },
  update(
    webAppId: number,
    webApp: Partial<WebApp> & { event?: string },
    reconnect: boolean,
  ) {
    const path = `${BASE_PATH}/${webAppId}`;

    return api({
      method: 'patch',
      url: path,
      data: serialize({ webApp: {
        ...webApp,
        event: reconnect ? 'heroku_reconnect' : undefined,
      } }),
    });
  },
  connect(webAppId: number) {
    const path = `${BASE_PATH}/${webAppId}`;

    return api({
      method: 'patch',
      url: path,
      data: { webApp: { event: 'heroku_reconnect' } },
    });
  },
  index(order?: WebAppOrderParam) {
    return api({
      method: 'get',
      url: BASE_PATH,
      params: { q: { s: decamelize(order || '') } },
    }).then(response => response.data?.webApps);
  },
  show(webAppId: number) {
    const path = `${BASE_PATH}/${webAppId}`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data?.webApp);
  },
  delete(webAppId: number) {
    const path = `${BASE_PATH}/${webAppId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
