import { camelize } from 'humps';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { computed, ComputedRef, reactive, watch } from 'vue';
import { useMutation } from 'vue-query';

import { update } from '@/api/section';
import type { Section } from '@/types/section';
import { SectionFieldsSchema } from '@/types/sections/section-fields-schema';
import { SectionOptions } from '@/types/sections/section-options';
import type { SectionUpdateFormData } from '@/types/sections/section-update-form-data';
import { camelizeSection } from '@/utils/sections-case-converter';

export type UseSectionDataProps = {
  section: Section;
  initialSection: Section;
  currentSectionFieldsKeys: ComputedRef<string[]>;
  currentSectionFieldsSchema: ComputedRef<SectionFieldsSchema>;
}

export type SetSectionFieldsValuesProps = {
  section: Section;
  currentSectionFieldsSchema: ComputedRef<SectionFieldsSchema>;
}

export type UseSectionDataToSaveProps = {
  section: Section
  sectionData: SectionUpdateFormData,
  initialSection: Section,
  currentSectionFieldsSchema: ComputedRef<SectionFieldsSchema>;
  currentSectionFieldsKeys: ComputedRef<string[]>;
}

export type UseSectionFieldsToSaveProps = {
  section: Section,
  sectionData: SectionUpdateFormData,
  currentSectionFieldsSchema: ComputedRef<SectionFieldsSchema>;
  currentSectionFieldsKeys: ComputedRef<string[]>;
}

export type UseUpdateSectionMutationProps = {
  currentSection: Section;
  initialSection: Section;
  sectionDataToSave: ComputedRef<Partial<SectionUpdateFormData>>;
}

export type UseSectionUpdateProps = {
  options: SectionOptions;
  section: Section;
}

function setSectionFieldsValues(
  { section, currentSectionFieldsSchema } : SetSectionFieldsValuesProps,
) {
  const sectionFields = section.sectionFields;
  const sectionFieldGroupedByGroupName = groupBy(sectionFields, (item) => camelize(item.groupName));

  const sectionFieldsValues = mapValues(currentSectionFieldsSchema.value, (value, key) => {
    if (value.group) {
      return sectionFieldGroupedByGroupName[key] || [];
    }

    return sectionFieldGroupedByGroupName[key]?.[0]?.value;
  });

  return sectionFieldsValues;
}

function useSectionData({
  section, initialSection, currentSectionFieldsKeys, currentSectionFieldsSchema,
}: UseSectionDataProps) {
  const sectionFieldsValues = computed(() => setSectionFieldsValues({ section, currentSectionFieldsSchema }));

  const initialSectionFieldsValues = reactive(cloneDeep(sectionFieldsValues.value));

  watch(() => initialSection, () => {
    Object.assign(initialSectionFieldsValues, cloneDeep(sectionFieldsValues.value));
  }, { deep: true });

  const sectionData: SectionUpdateFormData = reactive({
    type: camelize(section.type),
    baseType: camelize(section.baseType),
    position: section.position,
    sectionFields: cloneDeep(sectionFieldsValues.value),
  });

  watch(() => section.sectionFields, () => {
    Object.assign(sectionData.sectionFields, { ...sectionFieldsValues.value });
  });

  function resetObject(
    target: SectionUpdateFormData['sectionFields'],
    source: SectionUpdateFormData['sectionFields'],
  ) {
    Object.keys(target).forEach((key) => {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      } else {
        delete target[key];
      }
    });
  }

  function resetSectionData() {
    Object.assign(section, camelizeSection(initialSection));

    const initialSectionFields = Object.fromEntries(
      currentSectionFieldsKeys.value.map(key => [key, initialSectionFieldsValues[key]]),
    );
    resetObject(sectionData.sectionFields, initialSectionFields);
  }

  return { sectionData, resetSectionData };
}

function useSectionFieldsToSave(
  { section, sectionData, currentSectionFieldsSchema, currentSectionFieldsKeys } : UseSectionFieldsToSaveProps) {
  const sectionFieldsToSave = computed(() => {
    const sectionFieldGroupedByGroupName = groupBy(section.sectionFields, (item) => camelize(item.groupName));
    const sectionFields = sectionData.sectionFields;
    const sectionFieldsSchemaValue = currentSectionFieldsSchema.value || {};

    return pickBy(sectionFields,
      (value, key) => {
        if (!currentSectionFieldsKeys.value.includes(key)) return false;

        if (sectionFieldsSchemaValue[key]?.group) {
          return !isEqual(
            sectionFieldGroupedByGroupName[key] ?? [], value,
          );
        }

        return !isEqual(
          sectionFieldGroupedByGroupName[key]?.[0].value, value,
        );
      });
  });

  return { sectionFieldsToSave };
}

function useSectionDataToSave({
  section,
  sectionData,
  initialSection,
  currentSectionFieldsSchema,
  currentSectionFieldsKeys,
} : UseSectionDataToSaveProps) {
  const sectionOptionChanged = computed(() => (initialSection.type !== section.type));
  const sectionPositionChanged = computed(() => (initialSection.position !== section.position));
  const { sectionFieldsToSave } = useSectionFieldsToSave({
    section,
    sectionData,
    currentSectionFieldsSchema,
    currentSectionFieldsKeys,
  });
  const sectionFieldsChanged = computed(() => Object.keys(sectionFieldsToSave.value).length > 0);

  const sectionDataToSave = computed(() => ({
    type: sectionOptionChanged.value ? section.type : undefined,
    position: sectionPositionChanged.value ? section.position : undefined,
    sectionFields: sectionFieldsChanged.value ? sectionFieldsToSave.value : undefined,
  }));
  const needsSave = computed(() =>
    (sectionOptionChanged.value || sectionPositionChanged.value || sectionFieldsChanged.value),
  );

  return { sectionDataToSave, needsSave };
}

function useUpdateSectionMutation(
  { currentSection, initialSection, sectionDataToSave }: UseUpdateSectionMutationProps) {
  const {
    mutate: updateSectionMutation,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useMutation(
    () => update({ sectionId: currentSection.id, sectionData: sectionDataToSave.value }),
    {
      onSuccess: (response) => {
        Object.assign(initialSection, currentSection);
        initialSection.sectionFields = response.section.sectionFields;
        Object.assign(currentSection, camelizeSection(response.section));
      },
    },
  );

  return { updateSectionMutation, isUpdateLoading, isUpdateError, isUpdateSuccess };
}

export function useSectionUpdate({ section, options }: UseSectionUpdateProps) {
  const initialSection : Section = reactive(camelizeSection(section));
  const currentSection : Section = reactive({ ...initialSection });
  const currentSectionFieldsKeys = computed(() => Object.keys(options[currentSection.type] || {}));
  const currentSectionFieldsSchema = computed(() => options[currentSection.type]!);

  const { sectionData, resetSectionData } = useSectionData(
    { section: currentSection, initialSection, currentSectionFieldsKeys, currentSectionFieldsSchema },
  );
  const { sectionDataToSave, needsSave } = useSectionDataToSave(
    { section: currentSection, sectionData, initialSection, currentSectionFieldsSchema, currentSectionFieldsKeys },
  );
  const { updateSectionMutation, isUpdateLoading, isUpdateError, isUpdateSuccess } = useUpdateSectionMutation(
    { currentSection, initialSection, sectionDataToSave },
  );

  return {
    currentSection,
    currentSectionFields: sectionData.sectionFields,
    updateSection: updateSectionMutation,
    isUpdateLoading,
    isUpdateError,
    isUpdateSuccess,
    needsSave,
    resetForm: resetSectionData,
  };
}
