<script setup lang="ts">
const props = defineProps<{
  title: string
  subtitle?: string
  disclaimer?: string
  description?: string
  disabled?: boolean
  disabledDescription?: string
}>();

</script>

<template>
  <div>
    <div
      class="flex w-full flex-row items-center justify-between p-3"
    >
      <div :class="{'opacity-50': disabled}">
        <span class="pr-1 text-xs">
          <span class="font-semibold text-gray-900">{{ props.title }}</span>
          {{ props.subtitle }}
        </span>
        <span class=" text-xs font-normal text-gray-900"> {{ props.disclaimer }} </span>
        <p
          v-if="props.description"
          class=" text-xs font-normal leading-5 text-gray-500"
        >
          {{ props.description }}
        </p>
      </div>
      <slot name="toggle" />
    </div>
    <slot />
  </div>
</template>
