<script setup lang="ts">
import { MokButton, MokDropdown, MokIcon, MokInput } from '@mok-labs/components';
import { PhWarning } from '@phosphor-icons/vue';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { object, string } from 'yup';

import webAppApi from '@/api/webApp';
import type { Country } from '@/types/country';
import type { SalesWebAppForm } from '@/types/sales-web-app-form';

const props = defineProps<{
  backPath: string;
  countriesWithAvailableSalesHerokuApps: Country[];
}>();

const { t } = useI18n({});

const salesWebApp = reactive<Partial<SalesWebAppForm>>({
  countryId: undefined,
  name: undefined,
});

const salesWebAppSchema = object({
  countryId: string().required().label(t('webApp.fields.countryId')),
  name: string().required().label(t('webApp.fields.name')),
});

function isSalesWebApp(webApp: Partial<SalesWebAppForm>): webApp is SalesWebAppForm {
  return salesWebAppSchema.isValidSync(webApp);
}

const {
  mutate: createWebAppMutation,
  isLoading,
  isError,
} = useMutation(
  () => {
    if (isSalesWebApp(salesWebApp)) {
      return webAppApi.create(salesWebApp);
    }

    return Promise.reject();
  },
  { onSuccess: () => { window.location.href = props.backPath; } },
);

const areAllAppsUnavailable = computed(() => props.countriesWithAvailableSalesHerokuApps.length === 0);

</script>
<template>
  <v-form
    id="web-app-form"
    :validation-schema="salesWebAppSchema"
    validate-on-mount
    class="flex w-full flex-col gap-7"
    @submit="createWebAppMutation"
  >
    <div class="flex flex-col gap-2 rounded-lg border border-gray-200 px-6 py-8">
      <h2 class="text-sm font-semibold">
        {{ $t("webApp.title") }}
      </h2>
      <div class="flex justify-stretch gap-8">
        <mok-dropdown
          v-model="salesWebApp.countryId"
          class="w-full"
          :options="countriesWithAvailableSalesHerokuApps"
          :placeholder="$t('webApp.fields.countryId')"
          name="countryId"
          model-key="id"
          :disabled="areAllAppsUnavailable"
        />
        <mok-input
          v-model="salesWebApp.name"
          class="w-full"
          :placeholder="$t('webApp.fields.name')"
          name="name"
          :disabled="areAllAppsUnavailable"
        />
      </div>
      <div
        v-if="areAllAppsUnavailable"
        class="flex items-center gap-2 self-start rounded-full bg-warning-50 p-1 pr-2.5 text-xs text-warning-700"
      >
        <mok-icon
          :icon="PhWarning"
          size="sm"
          color="warning"
          class="bg-white"
        />
        {{ $t('webApp.errors.noAvailableSalesApps') }}
      </div>
    </div>
    <mok-button
      v-if="areAllAppsUnavailable"
      data-testid="go-back-button"
      :label="$t('webApp.actions.back')"
      :href="props.backPath"
      class="self-end"
    />
    <div
      v-else
      class="flex gap-4 self-end"
    >
      <mok-button
        data-testid="cancel-button"
        :label="$t('actions.cancel')"
        variant="secondary"
        :href="props.backPath"
      />
      <mok-button
        data-testid="save-button"
        :label="$t('actions.saveChanges')"
        type="submit"
        :loading="isLoading"
        class="self-end"
      />
    </div>
    <base-error
      v-if="isError"
      :message="$t('webApp.errors.create')"
    />
  </v-form>
</template>
