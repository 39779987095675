<script setup lang="ts">
import { ref, computed } from 'vue';
import { useQuery, useMutation } from 'vue-query';

import webAppsApi from '@/api/webApp';
import type { Permissions } from '@/types/permissions';
import type { WebApp } from '@/types/web-app';
import type { WebAppOrderParam } from '@/types/web-app-order-param';

import DeleteWebAppModal from './delete-web-app-modal.vue';
import WebAppsIndexHeader from './web-apps-index-header.vue';
import WebAppsIndexOwnersCell from './web-apps-index-owners-cell.vue';
import WebAppsIndexStatusPill from './web-apps-index-status-pill.vue';

const props = defineProps<{
  webApps: WebApp[],
  newWebAppUrl: string,
  permissions: Permissions,
}>();

const selectedWebApp = ref<WebApp>();
const isDeleteWebAppModalOpen = ref(false);
const selectedOrder = ref<WebAppOrderParam>();

function handleDelete(webApp: WebApp) {
  selectedWebApp.value = webApp;
  isDeleteWebAppModalOpen.value = true;
}

function closeModal() {
  isDeleteWebAppModalOpen.value = false;
}

function useWebAppsQuery() {
  return useQuery(['web-apps'],
    () => (webAppsApi.index(selectedOrder.value)),
    { initialData: props.webApps,
      enabled: false, retry: false },
  );
}
const {
  data: webAppsData,
  refetch: refetchWebApps,
} = useWebAppsQuery();

const updatedWebApps = computed(() => (webAppsData.value || []));

const {
  mutate: deleteWebAppMutation,
} = useMutation(
  (webAppId: number) => (webAppsApi.delete(webAppId)),
  {
    onSuccess: () => {
      isDeleteWebAppModalOpen.value = false;
      refetchWebApps.value();
    },
  },
);

function isShowWebAppButtonDisabled(webApp: WebApp) {
  return !webApp.applicationHost || !webApp.herokuConnected;
}

function handleOrderChange(order: WebAppOrderParam) {
  selectedOrder.value = order;
  refetchWebApps.value();
}
</script>

<template>
  <the-navbar />
  <div class="h-full flex-1 shrink-0 bg-gray-50">
    <div class="px-8 pb-10 md:px-40">
      <div class="flex items-center justify-between py-8">
        <div class="text-xl">
          {{ $t('webApp.titles.index') }}
        </div>
        <base-button
          v-if="permissions?.webApps?.create"
          :href="newWebAppUrl"
          icon-file-name="plus-circle"
          :label="$t('webApp.actions.new')"
          theme="primary"
        />
      </div>
      <div class="rounded-2xl border border-gray-200 bg-white">
        <table class="w-full divide-y divide-gray-200 text-left text-xs">
          <thead class="font-semibold text-gray-500">
            <tr>
              <th />
              <web-apps-index-header
                data-testid="sponsor-name-header"
                header-i18n-key="sponsorName"
                header-field="sponsorName"
                :selected-order="selectedOrder"
                @order="(order) => handleOrderChange(order)"
              />
              <web-apps-index-header
                data-testid="country-header"
                header-i18n-key="country"
                header-field="countryName"
                :selected-order="selectedOrder"
                @order="(order) => handleOrderChange(order)"
              />
              <web-apps-index-header
                data-testid="owner-header"
                header-i18n-key="owner"
                header-field="ownerName"
                :selected-order="selectedOrder"
                @order="(order) => handleOrderChange(order)"
              />
              <web-apps-index-header
                data-testid="app-type-header"
                header-i18n-key="appType"
                header-field="appType"
                :selected-order="selectedOrder"
                @order="(order) => handleOrderChange(order)"
              />
              <web-apps-index-header
                data-testid="app-name-header"
                header-i18n-key="appName"
                header-field="name"
                :sortable="false"
              />
              <web-apps-index-header
                data-testid="status-header"
                header-i18n-key="status"
                header-field="status"
                :sortable="false"
              />
              <th />
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr
              v-for="webApp in updatedWebApps"
              :key="webApp.id"
            >
              <td class="flex items-center justify-center py-4">
                <base-rounded-logo
                  :image-url="webApp.faviconLogoUrl"
                />
              </td>
              <td>
                <div class="text-sm font-semibold">
                  {{ webApp.sponsorName }}
                </div>
              </td>
              <td>
                <div class="text-center text-xl font-medium">
                  {{ webApp.countryFlagIcon }}
                </div>
              </td>
              <td>
                <web-apps-index-owners-cell
                  :web-app="webApp"
                />
              </td>
              <td class="font-semibold">
                {{ $t(`webApp.fields.shortAppTypes.${webApp.appType}`) }}
              </td>
              <td>
                <div class="font-medium">
                  {{ webApp.name }}
                </div>
                <div class="font-normal text-gray-500">
                  {{ webApp.herokuAppName }}
                </div>
              </td>
              <td>
                <web-apps-index-status-pill
                  :status="webApp.status"
                />
              </td>
              <td class="px-4">
                <div class="flex items-center">
                  <div class="flex-1 text-center">
                    <base-tooltip
                      data-testid="show-web-app-tooltip"
                      :text="$t('webApp.actions.show')"
                      :disabled="isShowWebAppButtonDisabled(webApp)"
                    >
                      <base-button
                        data-testid="show-web-app-button"
                        :href="webApp.applicationHost"
                        icon-file-name="eye"
                        :disabled="isShowWebAppButtonDisabled(webApp)"
                      />
                    </base-tooltip>
                  </div>
                  <div class="flex-1 text-center">
                    <base-tooltip
                      data-testid="performance-charts-tooltip"
                      :text="$t('webApp.actions.showData')"
                      :disabled="!webApp.performanceChartsUrl"
                    >
                      <base-button
                        data-testid="performance-charts-button"
                        :href="webApp.performanceChartsUrl"
                        icon-file-name="trending-up"
                        :disabled="!webApp.performanceChartsUrl"
                      />
                    </base-tooltip>
                  </div>
                  <div class="flex-1 text-center">
                    <base-tooltip
                      data-testid="edit-web-app-tooltip"
                      :text="$t('actions.edit')"
                      :disabled="!webApp.currentUserPermissions.edit"
                    >
                      <base-button
                        data-testid="edit-web-app-button"
                        :href="webApp.showPath"
                        icon-file-name="gear"
                        :disabled="!webApp.currentUserPermissions.edit"
                      />
                    </base-tooltip>
                  </div>
                  <div class="flex-1 text-center">
                    <base-tooltip
                      data-testid="delete-web-app-tooltip"
                      :text="$t('actions.delete')"
                      :disabled="!webApp.currentUserPermissions.destroy"
                    >
                      <base-button
                        data-testid="delete-web-app-button"
                        icon-file-name="bin"
                        :disabled="!webApp.currentUserPermissions.destroy"
                        @click.prevent="() => handleDelete(webApp)"
                      />
                    </base-tooltip>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <delete-web-app-modal
    v-if="selectedWebApp"
    :is-open="isDeleteWebAppModalOpen"
    :web-app="selectedWebApp"
    @delete-web-app="deleteWebAppMutation"
    @close-modal="closeModal"
  />
</template>
