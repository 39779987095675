<script setup lang="ts">
import { MokButton, MokIcon } from '@mok-labs/components';
import { PhX } from '@phosphor-icons/vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, type Component } from 'vue';

import BaseMokModal from '@/components/base-mok-modal.vue';

export type Props = {
  isOpen: boolean;
  /**  The image url. If provided, the icon will be ignored. */
  imageUrl?: string;
  /** The alt text of the image. Only necessary if the image url is provided*/
  imageAltText?: string;
  /** The svg of the icon. Search svgs in https://phosphoricons.com/ */
  icon?: Component;
  /** The color of the icon, primary by default. Only necessary if the icon is provided. */
  iconColor?: 'primary' | 'success' | 'error' | 'warning';
  title: string;
  /** Label of the secondary button. Without it the secondary button won't be visible */
  cancelLabel?: string;
  /** Label of the primary button */
  confirmLabel: string;
  /** Shows loading in primary button */
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  imageUrl: undefined,
  imageAltText: undefined,
  icon: undefined,
  iconColor: 'primary',
  cancelLabel: undefined,
  loading: false,
});

const emits = defineEmits<{(e: 'close'): void, (e: 'confirm'): void, (e: 'cancel'): void}>();

function closeModal() {
  emits('close');
}

const { greaterOrEqual } = useBreakpoints(breakpointsTailwind);
const smOrLarger = greaterOrEqual('sm');
const iconSize = computed(() => (smOrLarger.value ? 'xl' : 'lg'));
const closeIconSize = computed(() => (smOrLarger.value ? '24px' : '16px'));
const absoluteCloseButton = computed(() => !props.imageUrl && props.icon);
const buttonsSize = computed(() => (smOrLarger.value ? 'large' : 'medium'));

</script>

<template>
  <base-mok-modal
    :is-open="isOpen"
    class="p-8"
    @close="closeModal"
  >
    <template #default="{ setInitialFocusRef }">
      <header class="relative flex flex-col items-center gap-y-4 text-center">
        <button
          data-testid="close-button"
          class="self-end"
          :class="{'absolute right-0 top-0': absoluteCloseButton }"
          @click="closeModal"
        >
          <PhX
            :size="closeIconSize"
            class="fill-gray-400"
          />
        </button>
        <img
          v-if="imageUrl"
          class="h-32 w-full object-cover sm:h-60"
          :src="imageUrl"
          :alt="imageAltText"
        >
        <mok-icon
          v-else-if="icon"
          :color="iconColor"
          :icon="icon"
          :size="iconSize"
          class="mb-2"
        />
        <h3 class="text-xl font-semibold leading-8 text-gray-900 md:text-2xl md:leading-10">
          {{ title }}
        </h3>
        <div class="text-sm font-normal text-gray-800 md:text-base">
          <slot />
        </div>
      </header>
      <div
        data-testid="buttons-container"
        class="mt-8 flex flex-col-reverse gap-y-4 sm:flex-row"
        :class="{'grid-cols-2 gap-x-6 sm:grid': cancelLabel && confirmLabel}"
      >
        <mok-button
          v-if="cancelLabel"
          variant="secondary"
          :size="buttonsSize"
          full-width
          :label="cancelLabel"
          @click="$emit('cancel')"
        />
        <mok-button
          :ref="setInitialFocusRef"
          variant="primary"
          :size="buttonsSize"
          full-width
          :label="confirmLabel"
          :loading="loading"
          @click="$emit('confirm')"
        />
      </div>
    </template>
  </base-mok-modal>
</template>
