<script setup lang="ts">
import { PhNotePencil, PhTrash } from '@phosphor-icons/vue';
import { computed, inject, ref, watch } from 'vue';
import { VueDraggable, DraggableEvent } from 'vue-draggable-plus';
import { useMutation } from 'vue-query';

import webAppServiceApi from '@/api/webAppService';
import { usePermissions } from '@/composables/usePermissions';
import { BenefitGroupWithServices } from '@/types/benefit-group-with-services';
import type { Permissions } from '@/types/permissions';
import { WebAppService } from '@/types/web-app-service';

import WebAppServicesServiceCard from './web-app-services-service-card.vue';

export interface Props {
  group: BenefitGroupWithServices;
  draggableTarget?: HTMLElement;
}
const props = defineProps<Props>();
interface Emits {
  (e: 'edit'): void;
  (e: 'delete'): void;
  (e: 'editService', service: WebAppService): void;
  (e: 'deleteService', service: WebAppService): void;
  (e: 'movedService'): void;
  (e: 'update:draggableTarget', draggableTarget?: HTMLElement): void;
}
const emits = defineEmits<Emits>();

const { resourcePermissions: benefitGroupPermissions } = usePermissions(
  'benefitGroups', inject<Permissions>('permissions'),
);

function editService(webAppService: WebAppService) {
  emits('editService', webAppService);
}
function deleteService(webAppService: WebAppService) {
  emits('deleteService', webAppService);
}
function editGroup() {
  emits('edit');
}
function deleteGroup() {
  emits('delete');
}
function emitTarget(event: DraggableEvent) {
  emits('update:draggableTarget', event.to);
}
function clearTarget() {
  emits('update:draggableTarget', undefined);
}

const draggableWebAppServices = ref(props.group.webAppServices);
watch(() => props.group.webAppServices, (newWebAppServices) => {
  draggableWebAppServices.value = newWebAppServices;
});

const draggableContainer = ref<InstanceType<typeof VueDraggable>>();
const isDraggingTarget = computed(() => draggableContainer.value?.$el === props.draggableTarget);

function updateWebAppServiceRequest(event: DraggableEvent) {
  const params = {
    benefitGroupId: props.group.id,
    position: (event.newIndex || 0) + 1,
  };

  return webAppServiceApi.update(event.data.id, params);
}
const { mutate: updateWebAppServiceGroup } = useMutation(
  updateWebAppServiceRequest,
  { onSuccess: () => emits('movedService') },
);
</script>

<template>
  <div
    class="flex flex-col rounded-lg border border-dashed px-4 pt-8 shadow-lg"
    :class="isDraggingTarget ? 'border-primary-300' : 'border-gray-100 hover:border-gray-300'"
  >
    <div
      data-testid="group-actions-container"
      class="group relative mb-6 rounded-lg border border-gray-300 px-4 py-2.5 font-semibold text-black"
    >
      {{ group.name }}
      <div
        data-testid="group-actions"
        class="absolute inset-y-0 right-0 my-auto hidden h-fit gap-2 rounded-l border-y border-l border-gray-300 bg-white py-1 pl-2 pr-1 group-hover:flex"
      >
        <button
          v-if="benefitGroupPermissions.update"
          data-testid="edit-group"
          @click="editGroup"
        >
          <component
            :is="PhNotePencil"
            class="h-4 w-4 fill-gray-500"
          />
        </button>
        <button
          v-if="benefitGroupPermissions.destroy"
          data-testid="delete-group"
          @click="deleteGroup"
        >
          <component
            :is="PhTrash"
            class="h-4 w-4 fill-gray-500"
          />
        </button>
      </div>
    </div>
    <div class="h-px bg-gray-100" />

    <VueDraggable
      ref="draggableContainer"
      v-model="draggableWebAppServices"
      data-testid="group-column"
      class="flex flex-col gap-6 py-8 pt-6"
      group="services"
      ghost-class="border-primary-300"
      drag-class="border-primary-300"
      animation="150"
      @move="emitTarget"
      @add="updateWebAppServiceGroup"
      @update="updateWebAppServiceGroup"
      @end="clearTarget"
    >
      <web-app-services-service-card
        v-for="(webAppService, index) in draggableWebAppServices"
        :key="`${webAppService.id}-${index}`"
        :web-app-service="webAppService"
        class="cursor-grab"
        @edit="editService"
        @delete="deleteService"
      />
    </VueDraggable>
  </div>
</template>
