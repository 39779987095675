<script setup lang="ts">
import { inject } from 'vue';

import { usePermissions } from '@/composables/usePermissions';
import type { Permissions } from '@/types/permissions';
import { WebAppService } from '@/types/web-app-service';

import WebAppServicesServiceKindBadge from './web-app-services-service-kind-badge.vue';

interface Props {
  webAppService: WebAppService;
}
const props = defineProps<Props>();

const { resourcePermissions: webAppServicesPermissions } = usePermissions(
  'webAppServices', inject<Permissions>('permissions'),
);

interface Emits {
  (event: 'edit', value: WebAppService): void;
  (event: 'delete', value: WebAppService): void;
}
const emit = defineEmits<Emits>();
</script>

<template>
  <div
    class="flex flex-col justify-between gap-y-2 rounded-lg border border-gray-300 bg-gray-50 px-4 py-3"
    data-testid="service-card"
  >
    <span class="mr-auto text-xs text-gray-900">
      {{ props.webAppService.name }}
    </span>
    <div class="flex">
      <web-app-services-service-kind-badge
        class="px-2 py-0.5 text-2xs leading-3"
        :kind="props.webAppService.kind"
        :web-app-service-type="props.webAppService.type"
      />
      <button
        v-if="webAppServicesPermissions.update"
        class="ml-auto mr-2"
        data-testid="edit-service-button"
        @click="emit('edit', props.webAppService)"
      >
        <base-svg
          class="h-4 w-4 stroke-gray-900"
          name="note-pencil"
        />
      </button>
      <button
        v-if="webAppServicesPermissions.destroy"
        data-testid="delete-service-button"
        @click="emit('delete', props.webAppService)"
      >
        <base-svg
          class="h-4 w-4 stroke-gray-900"
          name="trash"
        />
      </button>
    </div>
  </div>
</template>
