<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhPlusCircle } from '@phosphor-icons/vue';
import { ref, provide, computed } from 'vue';
import { useMutation } from 'vue-query';

import webAppCouponApi from '@/api/webAppCoupon';
import { usePermissions } from '@/composables/usePermissions';
import useWebAppCouponsQuery from '@/composables/useWebAppCouponsQuery';
import type { Coupon } from '@/types/coupon';
import type { CuponstarCategory } from '@/types/cuponstar-category';
import type { Permissions } from '@/types/permissions';
import type { WebApp } from '@/types/web-app';
import type { WebAppCoupon } from '@/types/web-app-coupon';
import type { WebAppTabs } from '@/types/web-app-tabs';

import WebAppFunctionalitiesCouponsAdd from './web-app-functionalities-coupons-add.vue';
import WebAppFunctionalitiesCouponsList from './web-app-functionalities-coupons-list.vue';
import WebAppFunctionalitiesLayout from './web-app-functionalities-layout.vue';

export type Props = {
  webApp: WebApp;
  webAppCoupons: WebAppCoupon[];
  availableCoupons: Coupon[];
  cuponstarCategories: CuponstarCategory[];
  backPath: string,
  webAppTabs: WebAppTabs;
  permissions: Permissions;
}

const props = defineProps<Props>();

provide('webApp', props.webApp as WebApp);
provide('webAppCoupons', props.webAppCoupons as WebAppCoupon[]);
provide('availableCoupons', props.availableCoupons as Coupon[]);
provide('cuponstarCategories', props.cuponstarCategories as CuponstarCategory[]);
provide('permissions', props.permissions);

const { resourcePermissions: webAppCouponsPermissions } = usePermissions(
  'webAppCoupons', props.permissions,
);

const isUpdateSuccess = ref(false);
const isUpdateError = ref(false);

const webAppId = computed(() => props.webApp?.id || 0);

const isAddingCoupons = ref(false);
const couponIdsToAdd = ref<Set<number>>(new Set());

const {
  refetch,
} = useWebAppCouponsQuery(
  webAppId.value,
  props.webAppCoupons,
);

const { mutate } = useMutation(
  (couponIds : number[]) => webAppCouponApi.bulkCreate(
    webAppId.value,
    couponIds,
  ),
  {
    onSuccess: () => {
      refetch.value();
      couponIdsToAdd.value = new Set();
      isAddingCoupons.value = false;
    },
  },
);

function saveAddedCoupons() {
  mutate(Array.from(couponIdsToAdd.value));
}
</script>

<template>
  <web-app-functionalities-layout
    :web-app="webApp"
    :back-path="backPath"
    :web-app-tabs="webAppTabs"
    selected-tab="coupons"
    :is-update-success="isUpdateSuccess"
    :is-update-error="isUpdateError"
  >
    <div class="flex flex-col gap-4 pb-3 text-xs text-gray-600">
      <div
        v-if="webAppCouponsPermissions.create"
        class="order-last self-end md:order-first"
      >
        <mok-button
          v-if="!isAddingCoupons"
          variant="primary"
          :icon="PhPlusCircle"
          :label="$t('functionalities.coupons.addCoupons')"
          data-testid="add-coupons-button"
          @click="isAddingCoupons = true"
        />
        <div
          v-else
          class="flex gap-2"
        >
          <mok-button
            variant="secondary"
            :label="$t('actions.cancel')"
            data-testid="cancel-button"
            @click="isAddingCoupons = false"
          />
          <mok-button
            variant="primary"
            :label="$t('actions.save')"
            data-testid="save-button"
            @click="saveAddedCoupons"
          />
        </div>
      </div>
      <web-app-functionalities-coupons-list
        v-if="!isAddingCoupons"
      />
      <web-app-functionalities-coupons-add
        v-else
        :coupon-ids-to-be-added="couponIdsToAdd"
        @add-coupon-id="(couponId: number) => couponIdsToAdd.add(couponId)"
        @undo-add-coupon-id="(couponId: number) => couponIdsToAdd.delete(couponId)"
      />
    </div>
  </web-app-functionalities-layout>
</template>
