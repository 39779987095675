import { Permissions, PermissionKey } from '@/types/permissions';

export function usePermissions(key : PermissionKey, permissions: Permissions | undefined) {
  const defaultPermissions = {
    create: false,
    update: false,
    destroy: false,
  };

  return {
    resourcePermissions: {
      ...defaultPermissions,
      ...permissions?.[key],
    },
  };
}
